.handsontable col.rowHeaders {
  width: 200px;
}

.spinner {
  width: min-content;
  position: relative;
  left: 50%;
  top: 30vh;
  transform: translate(-50%);
}

.handsontable .wraptext {
  overflow-wrap: break-word;
  white-space: pre-line;
  max-width: 20px;
}

.accept {
  width: 100%;
  height: 100%;
  background-color: #1cc88a;
  border: none;
  margin: -4px !important;
  color: white;
  cursor: pointer;
}

.decline {
  width: 100%;
  height: 100%;
  background-color: #ce530e;
  border: none;
  margin: -4px !important;
  color: white;
  cursor: pointer;
}

.pegination {
  width: max-content;
  position: relative;
  left: 50%;
  transform: translate(-50%);
}

td.custom-cells {
  color: rgb(94, 93, 93);
  text-align: center;
}
td.col {
  font-weight: bold;
}

td.cell1 {
  background-color: #030235;
  color: white;
}

td.compSet {
  background-color: #becbda;
  border-bottom: 1px solid rgb(142, 139, 139);
  color: black;
}

td.leftToSell {
  background-color: #ffa400;
}

td.marketDemand {
  background-color: #e7e8d0;
}
