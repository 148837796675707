.menuHeader {
  display: flex;
  flex-direction: rows;
  justify-content: space-between;
  border-bottom: 2px solid var(--button-color);
}

.revLogo {
  margin-left: 20px;
}

.revImg {
  width: 90%;
  height: 40px;
  margin-top: 0.5vh;
}

.accountLogo {
  margin-right: 20px;
  margin-top: 0.5vh;
  display: flex;
}

.HotelIcon {
  cursor: pointer;
}

/* media queries */

@media (max-width: 576px) {
  .revImg {
    display: block;
    width: auto;
    height: 40px;
  }

  .accountLogo {
    margin-right: 20px;
  }
}
